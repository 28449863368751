import React, {useEffect, useState} from 'react';
import {Divider, Grid, Typography} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import api from '../core/api';

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: 100,
};

const colStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 100,
  marginTop: 10,
};

const CountDetails = () => {
  const [usersCount, setUsersCount] = useState();

  const handlerCountDatails = async () => {
    const count = await api.get('/users/count');
    setUsersCount(count.data);
  };

  useEffect(() => {
    handlerCountDatails();
  }, []);

  return (
    <div style={{...rowStyle, paddingTop: 20}}>
      <div style={colStyle}>
        <Typography component="h1" variant="subtitle1" color="inherit" noWrap>
          USUÁRIOS
        </Typography>
        <InputLabel htmlFor="age-native-helper"></InputLabel>
        <div style={rowStyle}>
          <div style={{minWidth: '100px'}}>
            <InputLabel htmlFor="age-native-helper">
              Total de usuários
            </InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_total}
            </InputLabel>
          </div>

          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Usuários Admin</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_admin}
            </InputLabel>
          </div>
        </div>
      </div>
      <Divider orientation="vertical" style={{marginLeft: 10}} />

      <div style={colStyle}>
        <Typography component="h1" variant="subtitle1" color="inherit" noWrap>
          PLANOS
        </Typography>
        <div style={rowStyle}>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Usuários novos</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_new}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Usuários Free</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_free}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">
              Usuário Plano Mensal
            </InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_monthly}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">
              Usuário Plano Anual
            </InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_yearly}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Usuário Gift</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_gift}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">
              Usuário Especial
            </InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_special}
            </InputLabel>
          </div>
        </div>
      </div>
      <Divider orientation="vertical" style={{marginLeft: 10}} />

      <div style={colStyle}>
        <Typography component="h1" variant="subtitle1" color="inherit" noWrap>
          ONBOARDING
        </Typography>
        <div style={rowStyle}>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Fez onboarding</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.do_onboarding}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">No onboarding</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.no_onboarding}
            </InputLabel>
          </div>
        </div>
      </div>
      <Divider orientation="vertical" style={{marginLeft: 10}} />

      <div style={colStyle}>
        <Typography component="h1" variant="subtitle1" color="inherit" noWrap>
          ATIVOS (30 DIAS)
        </Typography>
        <div style={rowStyle}>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Ativos</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_active}
            </InputLabel>
          </div>
          <div style={{minWidth: '100px', marginLeft: 15}}>
            <InputLabel htmlFor="age-native-helper">Inativos</InputLabel>
            <InputLabel style={{fontWeight: 'bold'}}>
              {usersCount?.users_inactive}
            </InputLabel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDetails;
