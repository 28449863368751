import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  List,
  Avatar,
  Button,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import useReduxState from '../../../core/useReduxState';
import api from '../../../core/api';
import ReturnButton from '../../../components/ReturnButton';
import {Formik} from 'formik';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import InputText from '../../../components/forms/InputText';
import ButtonSubmit from '../../../components/forms/ButtonSubmit';
import EditorComponent from './ComponentEditorJs';
import InputMask from '../../../components/forms/InputMask';
import AnimatedView from '../../../components/AnimatedView';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {useFileContext} from '../../../components/singletons/FileContext';
import {ModalEditCampaignImage} from '../../../components/forms/ModalEditMidia';

const data = `<html>
  <style>
    .centerDiv {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
      border: 2px solid #000005;
      padding: 10;
      justify-content: center;
      align-items: center;
    }
    .centerImge {
      display: block;
      margin-left: auto;
      margin-right: auto;

      border: 2px solid #4c8aa7;
    }
    h2 {
      text-align: center;
    }
  </style>
  <div class="centerDiv">
    <img
      class="centerImge"
      src="https://tkfglm.stripocdn.email/content/guids/CABINET_7fd33c40169e03704d1270e6daef4713caba0ccc9cb48e1e9ed5cf120603f687/images/logo_horizontal.png"
      alt
      style="display: block; border: 0; outline: none; text-decoration: none; -ms-interpolation-mode: bicubic"
    />
    <h1>MONTE AQUI SUA CAMPANHA</h1>
  </div>
</html>
`;

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    height: '40vh',
    width: '50vw',
    borderRadius: '20px',
    backgroundColor: '#61b7c3',
    // boxShadow: "0 10px 16px 0 rgba(0,0,0,0.2)"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  label: {
    paddingTop: 30,
  },
}));

const CampaignForm = ({
  campaign = {
    id: '',
    body: '',
    button: '',
    link: '',
    title: '',
    fileUrl: '',
    startDate: '',
    endDate: '',
    usersType: '',
  },
  history,
}) => {
  const classes = useStyles();
  const editing = !!campaign.id;
  const [usersType, setUsersType] = useState([]);
  const [validatePay, setValidatePay] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(campaign?.fileUrl || null);
  const [getUser, setUser] = useReduxState({
    loading: false,
  });

  const [update, setUpdate] = useState(false);

  const SetUpdate = () => {
    usersType.includes('ALL') && setUsersType(['F', 'N', 'M', 'Y']);
    setUpdate(!update);
  };

  const {enqueueSnackbar} = useSnackbar();

  const validatePayload = async values => {
    try {
      const {data} = await api.put(`/campaign/active`, {
        usersType,
        ...(editing && {id: campaign.id}),
      });

      if (data.length > 0) {
        setOpenModalConfirm(data);
        return;
      }
      onSubmit(values);
    } catch (e) {
      console.log('e', e);
    }
  };

  const removeUsers = async premium => {
    try {
      await api.put(`/campaign/users`, {
        premium,
        campaign_id: campaign.id,
      });
      setUsersType(prevState => {
        return [...prevState.filter(item => item != premium)];
      });
    } catch ({response}) {
      enqueueSnackbar(
        response.data || 'Erro ao remover esse tipo de plano da campanha.',
      );
      setUser({loading: false});
    }
  };

  const onSubmit = async form => {
    console.log('err');
    const [startDay, startMonth, startYear] = form.startDate.split('/');
    const startDate = new Date(`${startYear}/${startMonth}/${startDay}`);
    const [endDay, endMonth, endYear] = form.endDate.split('/');
    const endDate = new Date(`${endYear}/${endMonth}/${endDay}`);

    if (isNaN(startDate)) {
      enqueueSnackbar('Data de início inválida', {
        preventDuplicate: true,
        variant: 'error',
        style: {whiteSpace: 'pre-line'},
      });
      return;
    }
    if (isNaN(endDate) || startDate > endDate) {
      enqueueSnackbar('Data de fim inválida', {
        preventDuplicate: true,
        variant: 'error',
        style: {whiteSpace: 'pre-line'},
      });
      return;
    }

    if (form.status === 'Active' && new Date() > endDate) {
      enqueueSnackbar('Data de fim menor que a data atual', {
        preventDuplicate: true,
        variant: 'error',
        style: {whiteSpace: 'pre-line'},
      });
      return;
    }

    if (!image) {
      enqueueSnackbar('Adicione uma imagem', {
        preventDuplicate: true,
        variant: 'error',
        style: {whiteSpace: 'pre-line'},
      });
      return;
    }

    const payload = {
      title: form.title,
      body: JSON.stringify({
        body: form.body || '',
        button: form.button || '',
        link: form.link || '',
      }),
      fileUrl: image,
      usersType: usersType,
      status: new Date() < startDate ? 'Inactive' : 'Active',
      startDate: `${startYear}/${startMonth}/${startDay}`,
      endDate: `${endYear}-${endMonth}-${endDay}`,
    };
    setUser({loading: true});
    try {
      if (editing) {
        await api.put(`/campaign/${campaign.id}`, payload).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Campanha alterada com sucesso!');
            history.push('/campaign');
          }
        });
      } else {
        await api.post(`/campaign`, payload).then(res => {
          // console.log(res);
          if (res.status === 200) {
            enqueueSnackbar('Nova Campanha cadastrada com sucesso!');
            history.push('/campaign');
          }
        });
      }
    } catch ({response}) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao cadastrar campanha. Tente novamente.',
      );
      setUser({loading: false});
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.title) {
      errors.title = 'Digite o Título da campanha';
      errorMessage += 'Título da Campanha\n';
    }

    if (!values.body) {
      errors.body = 'Digite a descriiçao da campanha';
      errorMessage += 'Título da Campanha\n';
    }

    if (!values.startDate) {
      errors.startDate = 'Digite a data de início';
      errorMessage += 'Data de Início\n';
    }

    if (!values.endDate) {
      errors.endDate = 'Digite a data de fim';
      errorMessage += 'Data de Fim\n';
    }

    if (!image) {
      errorMessage += 'Adicione uma imagem\n';
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  useEffect(() => {
    if (campaign.usersType.length > 0) {
      setUsersType(
        campaign.usersType.map(c => {
          return c.usersType;
        }),
      );
    }
  }, [campaign]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={campaign}
        validate={validateForm}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          !validatePay && usersType.length > 0
            ? validatePayload(values)
            : onSubmit(values);
        }}>
        {formikProps => (
          <Grid container xs={12}>
            <Grid item xs={12} alignContent="center">
              <Typography component="h1" variant="h5">
                <ReturnButton />
                {editing ? 'Editar Campanha' : 'Cadastrar Nova Campanha'}
              </Typography>
            </Grid>

            <ModalEditCampaignImage
              image={image}
              setImage={setImage}
              handleClose={handleClose}
              open={open}
            />

            <Grid item xs={12} md={12} lg={12}>
              {!getUser().loading ? (
                <form onSubmit={formikProps.handleSubmit}>
                  {editing && (
                    <FormControl
                      fullWidth
                      error={
                        formikProps.touched.status && formikProps.errors.status
                      }>
                      <AnimatedView>
                        <FormLabel>Status</FormLabel>
                      </AnimatedView>
                      <Select
                        style={{
                          borderRadius: '40px',
                          height: 50,
                          width: '50%',
                          border: 'solid 0.5px #d3d3d3',
                          padding: '0 20px',
                          marginTop: 10,
                        }}
                        variant="outlined"
                        value={formikProps.values.status}
                        label="Status Assinatura"
                        onChange={e => {
                          formikProps.setFieldValue('status', e.target.value);
                          if (e.target.value === 'Inactive') {
                            formikProps.setFieldValue(
                              'endDate',
                              moment().format('DD/MM/YYYY'),
                            );
                          }
                        }}>
                        {[
                          {key: 'Active', label: 'Ativo'},
                          {key: 'Inactive', label: 'Inativo'},
                        ].map(e => {
                          return <MenuItem value={e.key}>{e.label}</MenuItem>;
                        })}
                      </Select>
                      {formikProps.touched.status &&
                        formikProps.errors.status && (
                          <FormHelperText id="component-error-text">
                            {formikProps.touched.status &&
                              formikProps.errors.status}
                          </FormHelperText>
                        )}
                    </FormControl>
                  )}
                  <AnimatedView>
                    <FormLabel>Título</FormLabel>
                  </AnimatedView>
                  <InputText
                    errorMessage={
                      formikProps.touched.nome && formikProps.errors.nome
                    }
                    id="title"
                    label=""
                    name="title"
                    value={formikProps.values.title}
                    autoComplete="title"
                    onChange={e => {
                      formikProps.setFieldValue('title', e.target.value);
                      formikProps.errors['title'] = null;
                    }}
                  />
                  <AnimatedView>
                    <FormLabel>Descrição</FormLabel>
                  </AnimatedView>
                  <InputText
                    errorMessage={
                      formikProps.touched.body && formikProps.errors.body
                    }
                    id="body"
                    label=""
                    name="body"
                    value={formikProps.values.body}
                    autoComplete="v"
                    onChange={e => {
                      formikProps.setFieldValue('body', e.target.value);
                      formikProps.errors['body'] = null;
                    }}
                  />

                  <div style={{w: '100%', display: 'flex'}}>
                    <FormControl fullWidth>
                      <AnimatedView>
                        <FormLabel>Nome do botão</FormLabel>
                      </AnimatedView>
                      <InputText
                        id="button"
                        label=""
                        name="button"
                        value={formikProps.values.button}
                        autoComplete="v"
                        onChange={e => {
                          formikProps.setFieldValue('button', e.target.value);
                          formikProps.errors['button'] = null;
                        }}
                      />
                    </FormControl>
                    <div style={{marginLeft: 10}} />

                    <FormControl fullWidth>
                      <AnimatedView>
                        <FormLabel>Link do botão</FormLabel>
                      </AnimatedView>
                      <InputText
                        id="link"
                        label=""
                        name="link"
                        value={formikProps.values.link}
                        autoComplete="v"
                        onChange={e => {
                          formikProps.setFieldValue('link', e.target.value);
                          formikProps.errors['link'] = null;
                        }}
                      />
                    </FormControl>
                  </div>

                  <div style={{w: '100%', display: 'flex'}}>
                    <FormControl
                      fullWidth
                      error={
                        formikProps.touched.usersType &&
                        formikProps.errors.usersType
                      }>
                      <AnimatedView>
                        <FormLabel>Tipo de Plano</FormLabel>
                      </AnimatedView>

                      <Select
                        style={{
                          borderRadius: '40px',
                          height: 50,
                          border: 'solid 0.5px #d3d3d3',
                          padding: '0 20px',
                          marginTop: 10,
                        }}
                        variant="outlined"
                        value="Tipo de Plano"
                        label="Tipo de Plano"
                        onChange={e => {
                          setValidatePay(false);
                          !usersType.includes(e.target.value) &&
                            usersType.push(e.target.value);
                          SetUpdate();
                        }}>
                        {[
                          {key: 'N', label: 'Novo'},
                          {key: 'F', label: 'Free'},
                          {key: 'M', label: 'Mensal'},
                          {key: 'Y', label: 'Anual'},
                          {key: 'ALL', label: 'Todos'},
                        ].map(e => {
                          return <MenuItem value={e.key}>{e.label}</MenuItem>;
                        })}
                      </Select>
                      {formikProps.touched.usersType &&
                        formikProps.errors.usersType && (
                          <FormHelperText id="component-error-text">
                            {formikProps.touched.usersType &&
                              formikProps.errors.usersType}
                          </FormHelperText>
                        )}
                    </FormControl>

                    <div style={{marginLeft: 10}} />
                    <InputMask
                      fullWidth
                      errorMessage={
                        formikProps.touched.nome && formikProps.errors.nome
                      }
                      id="startDate"
                      label="Data de início"
                      name="startDate"
                      value={formikProps.values.startDate}
                      autoComplete="startDate"
                      onChange={e => {
                        formikProps.setFieldValue('startDate', e.target.value);
                        formikProps.errors['startDate'] = null;
                      }}
                    />

                    <div style={{marginLeft: 10}} />
                    <InputMask
                      id="endDate"
                      label="Data de fim"
                      name="endDate"
                      value={formikProps.values.endDate}
                      disabled={formikProps.values.status === 'Inactive'}
                      autoComplete="endDate"
                      onChange={e => {
                        formikProps.setFieldValue('endDate', e.target.value);
                        formikProps.errors['endDate'] = null;
                      }}
                    />
                  </div>

                  <div
                    style={{
                      maxWidth: '50%',
                      display: 'flex',
                    }}>
                    {usersType &&
                      usersType.length > 0 &&
                      usersType.map(user => (
                        <Chip
                          style={{margin: 5}}
                          variant="outlined"
                          label={GetPremium(user)}
                          onDelete={() => {
                            if (editing) {
                              removeUsers(user);
                            } else {
                              setUsersType(prevState => {
                                return [
                                  ...prevState.filter(item => item != user),
                                ];
                              });
                            }
                            SetUpdate();
                          }}
                        />
                      ))}
                  </div>

                  <Grid item xs={12} style={{padding: '20px'}}>
                    <div className="flex-col center-a center-b">
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleClickOpen}>
                        <Avatar className={classes.avatar} src={image}>
                          {!image && <AddAPhotoIcon style={{fontSize: 60}} />}
                        </Avatar>
                      </IconButton>

                      <p
                        style={{
                          color:
                            formikProps.touched.thumbnail &&
                            formikProps.errors.thumbnail &&
                            'red',
                        }}>
                        Selecione uma imagem para a Campanha{' '}
                        <strong>(320 x 120)</strong>
                      </p>
                    </div>

                    <Grid xs={12} container justify="center">
                      <Button onClick={handleClickOpen}>Editar Imagem</Button>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <ButtonSubmit
                      style={{
                        marginTop: 20,
                        width: '20vw',
                        alignSelf: 'center',
                      }}
                      type="submit"
                      variant="contained"
                      color="primary">
                      {editing ? 'Salvar' : 'Cadastrar'}
                    </ButtonSubmit>
                  </div>
                </form>
              ) : (
                <div
                  className="flex-col center-a center-b"
                  style={{height: '50vh'}}>
                  <CircularProgress size={100} />
                  <p> Carregando... </p>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Formik>
      <Dialog
        onClose={() => setOpenModalConfirm(false)}
        aria-labelledby="simple-dialog-title"
        open={openModalConfirm.length > 0}>
        <DialogTitle id="simple-dialog-title">
          já existem campanhas atreladas a esses tipos de assinaturas, deseja
          substituir?
        </DialogTitle>

        {openModalConfirm &&
          openModalConfirm.length > 0 &&
          openModalConfirm.map(items => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Chip
                  style={{margin: 5}}
                  variant="outlined"
                  label={`${GetPremium(items.usersType)} - ${
                    items.campaignTitle
                  }`}
                  onDelete={() => {
                    setOpenModalConfirm(prevState => {
                      return [
                        ...prevState.filter(
                          item => item.usersType != items.usersType,
                        ),
                      ];
                    });
                    setUsersType(prevState => {
                      return [
                        ...prevState.filter(item => item != items.usersType),
                      ];
                    });
                    SetUpdate();
                  }}
                />
              </div>
            );
          })}

        <ButtonSubmit
          style={{
            margin: 10,
            width: '20vw',
            alignSelf: 'center',
          }}
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setValidatePay(true);
            setOpenModalConfirm(false);
          }}>
          Confirmar
        </ButtonSubmit>
      </Dialog>
    </>
  );
};

export default CampaignForm;

export function GetPremium(premium) {
  switch (premium) {
    case 'N':
      return 'Plano N';
    case 'F':
      return 'Plano F';
    case 'M':
      return 'Plano M';
    case 'Y':
      return 'Plano Y';
    case 'ALL':
      return 'Todos';
    default:
      return 'Todos';
  }
}
