import React from 'react';
import {Button, withStyles} from '@material-ui/core';

const ButtonForm = ({children, ...props}) => {
  const StyledButton = withStyles({
    root: {
      borderRadius: '15px',
      height: 60,
      backgroundColor: '#61b7c3',
      color: '#fff',
    },
  })(Button);

  return (
    <StyledButton {...props} fullWidth variant="contained">
      {children}
    </StyledButton>
  );
};

export default ButtonForm;
