import React, {useRef} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../../../core/api';

const EditorComponent = ({body, setBody, setFormBody, setLoading}) => {
  const editorRef = useRef(null);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const formData = new FormData();
            console.log('file', file);
            formData.append('file', file);
            const response = await api.post(`campaign/images`, formData, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
              },
            });
            resolve({
              default: response.data,
            });
          } catch (error) {
            reject('Hello');
          }
        });
      },
      abort: () => {},
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div style={{}}>
      <CKEditor
        editor={ClassicEditor}
        data={body}
        config={{
          // @ts-ignore
          extraPlugins: [uploadPlugin],
          toolbar: [
            'undo',
            'redo',
            'paragraph',
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'numberedList',
            'bulletedList',
            'alignment',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'textColor',
            'fontSize',
            'styles',
          ],
          format_tags: ['p', 'h'],
          contentsCss: [
            'https://cdn.jsdelivr.net/npm/@ckeditor/ckeditor5@27.1.0/classic/build/contents.css',
            {
              fontSize: '16px', // tamanho da fonte
            },
          ],
        }}
        onChange={(event, editor) => {
          const body = editor.getData();
          setBody(body);
          setFormBody(body);
        }}
      />
    </div>
  );
};

export default EditorComponent;
