import React, {useState} from 'react';
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ButtonForm from '../../../components/forms/ButtonForm';
import api from '../../../core/api';
import {GetPremium} from './NewsletterForm';
import {ErrorOutline} from '@material-ui/icons';

export const ModalFormNewsletter = ({handleClose, open, id, usersType}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  // const [data, setData] = useState([]);

  const SendTestNewsletter = async () => {
    setLoading(true);
    try {
      const response = await api.post(`/newsletter/send-email/${id}`);
      // setData(response.data);
      setSendConfirm(true);
    } catch (response) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao enviar newsletter. Tente novamente.',
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const CloseModal = () => {
    handleClose();
    setSendConfirm(false);
    setLoading(false);
  };

  return (
    <Dialog
      onClose={CloseModal}
      aria-labelledby="simple-dialog-title"
      open={open}>
      {loading ? (
        <div className="flex-col center-a center-b">
          <DialogTitle id="simple-dialog-title">
            Enviando Newsletter!!
          </DialogTitle>
          <CircularProgress size={50} />
          <p> Aguarde... </p>
        </div>
      ) : sendConfirm ? (
        <div className="flex-col center-a center-b" style={{margin: 20}}>
          <CheckCircleIcon style={{color: 'green'}} />
          <DialogTitle id="simple-dialog-title">
            Newsletter enviada!!
          </DialogTitle>
          <p>
            {' '}
            Em caso de erro, todos devem verificar a caixa de entrada e pasta de
            spam.{' '}
          </p>
          {/* {data.length > 0 && (
            <>
              <Divider />
              <ErrorOutline style={{color: 'red', marginTop: 20}} />
              <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                Usuários que não receberam a Newsletter
              </p>
              <div
                style={{
                  maxWidth: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {data &&
                  data.map(user => (
                    <Chip
                      style={{margin: 5}}
                      variant="outlined"
                      label={`${user.name} - ${user.email}`}
                    />
                  ))}
              </div>
              <p style={{textAlign: 'center'}}>
                um erro inesperado aconteceu ao tentar enviar mensagem a esses
                usuários. <br /> Tente novamente.
              </p>
            </>
          )} */}
        </div>
      ) : (
        <>
          <DialogTitle id="simple-dialog-title">
            Deseja enviar a Newsletter para todos os usuários previamente
            selecionados?
          </DialogTitle>
          <p style={{textAlign: 'center'}}>Usuários dos planos</p>
          <div
            style={{
              maxWidth: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {usersType &&
              usersType.length > 0 &&
              usersType.map(user => (
                <Chip
                  style={{margin: 5}}
                  variant="outlined"
                  label={GetPremium(user)}
                />
              ))}
          </div>
          <p style={{textAlign: 'center'}}>
            receberão essa Newsletter desde que tenham aceita a permissão para
            receber.
          </p>
          <ButtonForm
            style={{
              margin: 10,
              width: '20vw',
              alignSelf: 'center',
            }}
            type="button"
            variant="contained"
            color="primary"
            disable={loading}
            onClick={SendTestNewsletter}>
            Confirmar
          </ButtonForm>
        </>
      )}
    </Dialog>
  );
};
