import React from 'react';
import Dashboard from '../../layout/Dashboard';
import {Grid, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NewsletterContainer from '../../components/containers/NewsletterContainer';
import NewsletterItem from '../../components/NewsletterItem';

const NewsletterPage = ({history}) => {
  return (
    <Dashboard>
      <Grid container justify="flex-end">
        {!window.location.pathname.match(/candidates/) && (
          <Button
            sx={{borderRadius: 10}}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push(`${window.location.pathname}/add`)}>
            Nova Newsletter
          </Button>
        )}
      </Grid>
      <NewsletterContainer>
        {news =>
          news.map(c => {
            return <NewsletterItem history={history} newsItem={c} />;
          })
        }
      </NewsletterContainer>
    </Dashboard>
  );
};

export default NewsletterPage;
