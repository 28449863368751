import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
 import UserForm from '../../components/forms/UserForm';
import api from '../../core/api';

const EditUser = ({history, match}) => {
  const [user, setUser] = useState({});

  const fetchUser = async () => {
    try {
      const {data: user} = await api.get(`/users/${match.params.userId}`);
      delete user.password;
      user.special = user.premium === 'S' ? true : false;
      const {data: partners} = await api.get(`/partners`);
      const partner = partners.find(partner => partner.id === user.partner_id);
      user.author = partner?.artisticName;
      const {data: empresas} = await api.get(`/empresas`);
      const empresa = empresas.find(empresa => empresa.id === user.empresa_id);
      user.nomeEmpresa = empresa?.nomeFantasia;
      const {data: specials} = await api.get(`/specials`);
      const special = specials.find(special => special.id === user.special_id);
      user.nomeSpecial = special?.nome;
      console.log('user', user);
      setUser(user);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Dashboard>
      {user.id && <UserForm history={history} user={user} />}
    </Dashboard>
  );
};

export default EditUser;
