import React from 'react';
import {Grid} from '@material-ui/core';
import {useAppContext} from './singletons/AppContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ButtonSubmit from './forms/ButtonSubmit';

const AddNewUser = ({}) => {
  const {history} = useAppContext();

  return (
    <Grid item>
      {!window.location.pathname.match(/candidates/) && (
        <button
          className="download-table-xls-button-flex"
          onClick={() =>
            history.push(
              window.location.pathname.includes('medias')
                ? '/uploads?startUpload=true'
                : `${window.location.pathname}/add`,
            )
          }>
          Cadastrar Usuários
        </button>
      )}
    </Grid>
  );
};

export default AddNewUser;
