import React, {useEffect, useState} from 'react';

import {TableRow, TableCell, IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Axios from 'axios';
import moment from 'moment';
import {useSnackbar} from 'notistack';

import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Confirm from '../../../components/Confirm';
import api from '../../../core/api';
import useStyles from './styles';
import {Link, Prompt} from 'react-router-dom';
import {getMediaType} from '../../../core/mediaTypeHelpers';

const UploadItem = ({
  mediaItem,
  onUploading,
  duration = -1,
  handleDelete,
  handleUpload,
}) => {
  const [isUploading, setIsUploading] = useState(true);
  const [totalUploaded, setTotalUploaded] = useState(0);
  const [uploadedAt, setUploadedAt] = useState('');
  const {enqueueSnackbar} = useSnackbar();

  const classes = useStyles();

  useEffect(() => {
    if (duration == 0) {
      enqueueSnackbar(
        `Tipo de conteúdo ${mediaItem.type} não suportado, deseja 
        continuar mesmo assim?`,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }
    if (!mediaItem.uploaded && duration > 0) {
      uploadMedia(mediaItem);
    } else {
      setIsUploading(false);
    }
  }, [mediaItem, duration]);

  const normalizeName = media => {
    return media.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  useEffect(() => {
    if (!isUploading) {
      setUploadedAt(moment().format('DD/MM/YYYY HH:mm:SS'));
    }
    onUploading(mediaItem.name, isUploading);
  }, [isUploading]);

  const uploadMedia = async media => {
    console.info(`Uploading media ${media.name}`);
    setIsUploading(true);
    const s3SignedUrlRequest = await api.post('/videos/signedurl-video', {
      filename: normalizeName(media),
      type: media.type,
      metadata: {
        duration: duration.toString(),
      },
    });
    if (s3SignedUrlRequest.status === 200) {
      const s3SignedUrl = s3SignedUrlRequest.data;
      const options = {
        headers: {
          'Content-Type': media.type,
          // 'x-amz-acl': 'public-read',
          duration: duration.toString(),
        },
        onUploadProgress: event => {
          if (event.lengthComputable) {
            setTotalUploaded((event.loaded / event.total) * 100);
          }
        },
      };

      //Convert media name removing accents
      const sendMedia = new File([media], normalizeName(media), {
        type: media.type,
      });
      await Axios.put(s3SignedUrl, sendMedia, options);
      setIsUploading(false);
      console.info(`Media ${media.name} successfully uploaded`);
      handleUpload(media);
    }
  };

  const deleteItem = async () => {
    const res = await Confirm('Deseja realmente excluir este Conteúdo?');
    if (res) {
      try {
        const deleteRequest = await api.delete(
          `/videos/uploads/${normalizeName(mediaItem)}`,
        );
        if (deleteRequest.status === 200) {
          handleDelete(mediaItem.name);
        }
      } catch (e) {
        console.dir(e);
      }
    }
  };
  return (
    <TableRow key={mediaItem.name} className={classes.uploadItem}>
      <Prompt
        when={isUploading}
        message="Existe conteúdo sendo enviado para o servidor.
         Se sair da página, o envio será cancelado."
      />
      <TableCell>
        {isUploading ? (
          <div className={classes.circularProgress}>
            <CircularProgressbar
              value={totalUploaded}
              text={`${totalUploaded.toFixed(1)}%`}
            />
          </div>
        ) : (
          'Salvo'
        )}
      </TableCell>
      <TableCell>{normalizeName(mediaItem)}</TableCell>
      <TableCell>{(mediaItem.size * Math.pow(10, -6)).toFixed(2)} MB</TableCell>
      <TableCell>
        {moment(mediaItem.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
      </TableCell>
      <TableCell>
        <div>
          <IconButton
            component={Link}
            to={`/medias/add/${normalizeName(mediaItem)}/${getMediaType(
              mediaItem.name,
            )}/${mediaItem.size}/${duration}`}
            edge={'end'}
            disabled={isUploading}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={deleteItem} disabled={isUploading}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default UploadItem;
