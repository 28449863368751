import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import api from '../../core/api';
import NewsletterForm from './components/NewsletterForm';
import moment from 'moment';

const EditNewsletters = ({history, match}) => {
  const [newsletters, setNewsletter] = useState({});

  const fetchNewsletter = async () => {
    try {
      const {data: newsletters} = await api.get(
        `/newsletter/${match.params.newsletterId}`,
      );
      const bodyFormat = newsletters.body ? JSON.parse(newsletters.body) : {};
      const users = newsletters.usersType
        ? JSON.parse(newsletters.usersType)
        : [];
      setNewsletter({
        ...newsletters,
        title: bodyFormat.title || '',
        image: bodyFormat.image || '',
        body: bodyFormat.body || '',
        button: bodyFormat.button || '',
        link: bodyFormat.link || '',
        usersType: users,
        // shippingDate: moment.utc(newsletters.shippingDate).format('DD/MM/YYYY'),
      });
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchNewsletter();
  }, []);

  return (
    <Dashboard>
      {newsletters.id && (
        <NewsletterForm history={history} news={newsletters} />
      )}
    </Dashboard>
  );
};

export default EditNewsletters;
