import React, {useState} from 'react';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  FormLabel,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AnimatedView from '../../../components/AnimatedView';
import InputText from '../../../components/forms/InputText';
import ButtonForm from '../../../components/forms/ButtonForm';
import api from '../../../core/api';

export const ModalFormNewsletterTest = ({handleClose, open, id}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [sendConfirm, setSendConfirm] = useState(false);
  const [form, setForm] = useState({
    name: null,
    email: null,
  });

  const SendTestNewsletter = async () => {
    if (!form.email || !form.name) {
      return enqueueSnackbar('Todos os campos são obrigatórios.');
    }

    setLoading(true);
    try {
      await api.post(`/newsletter/send-email-test/${id}`, {
        name: form.name,
        email: form.email,
      });
      setSendConfirm(true);
    } catch (response) {
      enqueueSnackbar(
        response.data.message ||
          'Ocorreu um erro ao enviar newsletter. Tente novamente.',
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const CloseModal = () => {
    handleClose();
    setForm({
      name: null,
      email: null,
    });
    setSendConfirm(false);
    setLoading(false);
  };

  return (
    <Dialog
      onClose={CloseModal}
      aria-labelledby="simple-dialog-title"
      open={open}>
      {loading ? (
        <div className="flex-col center-a center-b">
          <DialogTitle id="simple-dialog-title">
            Enviando Newsletter teste!!
          </DialogTitle>
          <CircularProgress size={50} />
          <p> Aguarde... </p>
        </div>
      ) : sendConfirm ? (
        <div className="flex-col center-a center-b" style={{margin: 20}}>
          <CheckCircleIcon style={{color: 'green'}} />
          <DialogTitle id="simple-dialog-title">
            Newsletter enviada!!
          </DialogTitle>
          <p>
            {' '}
            Caso não receba o e-mail, verifique sua caixa de entrada e pasta de
            spam.{' '}
          </p>
        </div>
      ) : (
        <>
          <DialogTitle id="simple-dialog-title">
            Preencha as informações abaixo para enviar o e-mail de teste?
          </DialogTitle>
          <div
            style={{
              margin: 20,
            }}>
            <AnimatedView>
              <FormLabel>Nome teste</FormLabel>
            </AnimatedView>
            <InputText
              id="name"
              label=""
              name="name"
              value={form.name}
              autoComplete="name"
              onChange={e => {
                setForm({...form, name: e.target.value});
              }}
            />

            <AnimatedView>
              <FormLabel>Email teste</FormLabel>
            </AnimatedView>
            <InputText
              id="email"
              label=""
              name="email"
              value={form.email}
              autoComplete="email"
              onChange={e => {
                setForm({...form, email: e.target.value});
              }}
            />
          </div>
          <ButtonForm
            style={{
              margin: 10,
              width: '20vw',
              alignSelf: 'center',
            }}
            type="button"
            variant="contained"
            color="primary"
            disable={loading}
            onClick={SendTestNewsletter}>
            Confirmar
          </ButtonForm>
        </>
      )}
    </Dialog>
  );
};
