import React, {useEffect, useState} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import DrawTable from '../../components/DrawTable';
import {GetSubscriptionType} from '.';
import moment from 'moment';
import api from '../../core/api';

const ButtonExport = ({
  subscriptionType,
  filterValue,
  adminSelect,
  newsletterType,
  onboardingUser,
  activeUser,
  b2bFilter,
  selectEmpresa,
}) => {
  const [dataExport, setDataExport] = useState([]);

  async function fetchUsers() {
    try {
      const filters = {
        name: filterValue,
        isAdmin: adminSelect,
        premium: subscriptionType,
        newsletter: newsletterType,
        onboarding: onboardingUser,
        activeUser: activeUser,
        b2b: b2bFilter,
        company: selectEmpresa,
      };

      const {data} = await api.get('users', {
        params: {
          page: 1,
          pageSize: 99999999,
          filters,
          subscriptionType,
        },
      });

      const dataEx = data?.users?.map(user => {
        const userFormatted = {
          nome: user?.name,
          email: user?.email,
          recebe_newsletter: user?.receiveNewsletter ? 'Sim' : 'Não',
          assinatura: GetSubscriptionType(user?.premium),
          data: moment.utc(user?.createdAt).format('DD/MM/YYYY'),
          onboarding: !!user?.onboarding_date ? 'Sim' : 'Não',
          usuario_ativo: user?.is_active ? 'Sim' : 'Não',
          empresa: user?.empresa?.nomeFantasia || '',
        };
        return userFormatted;
      });

      setDataExport(dataEx);
    } catch (err) {
    } finally {
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [
    filterValue,
    subscriptionType,
    b2bFilter,
    adminSelect,
    newsletterType,
    onboardingUser,
    activeUser,
    selectEmpresa,
  ]);

  return (
    <>
      <ReactHTMLTableToExcel
        id="partners-table-xls-button"
        table="partners-to-xls"
        className="download-table-xls-button-flex"
        filename={`users_${new Date()}`}
        sheet="tablexls"
        buttonText="Exportar Usuários"
      />
      <div style={{visibility: 'hidden', height: 10}}>
        {DrawTable(
          [
            'Nome',
            'Email',
            'Recebe Newsletter',
            'Assinatura',
            'Data',
            'Onboarding',
            'Empresa',
          ],
          dataExport.map(user => [
            user.nome,
            user.email,
            user.recebe_newsletter,
            user.assinatura,
            user.data,
            user.onboarding,
            user.empresa,
          ]),
          'partners-to-xls',
        )}
      </div>
    </>
  );
};

export default ButtonExport;
