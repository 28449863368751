import React, {useState} from 'react';
import {
  TextField,
  withStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  FormLabel,
  Button,
  img,
} from '@material-ui/core';
import AnimatedView from '../AnimatedView';

const StyledInput = withStyles({
  root: {
    borderRadius: '40px',
    height: 50,
    border: 'solid 1px #d3d3d3',
    padding: '0 20px',
  },
})(TextField);

const InputImage = ({
  label,
  errorMessage,
  leftIcon,
  rigthElement,
  ...props
}) => {
  return (
    <FormControl fullWidth error={errorMessage}>
      {props.value ? (
        <AnimatedView>
          <FormLabel>{label}</FormLabel>
        </AnimatedView>
      ) : (
        <div id="box"></div>
      )}
      <StyledInput
        type="file"
        accept="image/jpeg, image/png"
        style={{...props.style, height: props.id == 'description' && '100%'}}
        placeholder={label}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{color: '#d3d3d3'}}>
              {leftIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{color: '#d3d3d3'}}>
              {rigthElement}
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {errorMessage && (
        <FormHelperText id="component-error-text">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputImage;
