import React, {useEffect, useState} from 'react';
import Dashboard from '../../layout/Dashboard';
import UserItem from '../../components/UserItem';
import {
  CircularProgress,
  List,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import styles from './styles.module.css';
import api from '../../core/api';
import AddNewUser from '../../components/AddUser';
import Specials from '../../models/Specials';
import Empresas from '../../models/Empresas';
import ButtonExport from './buttonExport';

export const GetSubscriptionType = premium => {
  switch (premium) {
    case 'F':
      return 'Free';
    case 'M':
      return 'Mensal';
    case 'Y':
      return 'Anual';
    case 'N':
      return 'New';
    case 'G':
      return 'Gift';
    case 'S':
      return 'Parceiro Especial';
    default:
      return premium;
  }
};

const UsersPage = ({history}) => {
  const [users, setUsers] = useState([]);
  const [getEmpresas, setEmpresas] = useState([]);
  const [getSpecial, setSpecial] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [isLoading, setIsFirstLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const [selectEmpresa, setSelectEmpresa] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [b2bFilter, setB2bFilter] = useState('');
  const [newsletterType, setNewsletterType] = useState();
  const [onboardingUser, setOnboardingUser] = useState();
  const [activeUser, setActiveUser] = useState();
  const [adminSelect, setAdminSelect] = useState(false);
  const [error, setError] = useState(false);
  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleFilterChange = event => {
    setFilterValue(event.target.value);
    setCurrentPage(1);
  };

  const handleSubscriptionTypeChange = event => {
    setSubscriptionType(event.target.value);
    setB2bFilter('');
    setCurrentPage(1);
  };

  const handleB2bTypeChange = event => {
    setB2bFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleNewsletterTypeChange = event => {
    setNewsletterType(event.target.value);
    setCurrentPage(1);
  };

  const handleOnboardingChange = event => {
    setOnboardingUser(event.target.value);
    setCurrentPage(1);
  };

  const handleUsersActiveChange = event => {
    setActiveUser(event.target.value);
    setCurrentPage(1);
  };

  const handleAdminSelectChange = event => {
    setAdminSelect(event.target.value);
    setCurrentPage(1);
  };

  async function fetchUsers() {
    try {
      if (users.length < 1) {
        setFirstLoading(true);
      }
      setIsFirstLoading(true);
      const filters = {
        name: filterValue,
        isAdmin: adminSelect,
        premium: subscriptionType,
        newsletter: newsletterType,
        onboarding: onboardingUser,
        activeUser: activeUser,
        b2b: b2bFilter,
        company: selectEmpresa,
      };

      const {data} = await api.get('users', {
        params: {
          page: currentPage,
          pageSize,
          filters,
          subscriptionType,
        },
      });
      setUsers(data.users);
      setTotalPages(data.totalUsers);
    } catch (err) {
      setError(true);
    } finally {
      setError(false);
      setFirstLoading(false);
      setIsFirstLoading(false);
    }
  }

  const fetchSpecial = async () => {
    try {
      const allSpecials = await Specials.findAll();
      setSpecial(allSpecials);
    } catch (e) {
      console.log({e});
    }
  };

  const fetchEmpresa = async () => {
    try {
      const allEmpresas = await Empresas.findAll();
      setEmpresas(allEmpresas);
    } catch (e) {
      // console.log({e});
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [
    currentPage,
    pageSize,
    filterValue,
    subscriptionType,
    b2bFilter,
    adminSelect,
    newsletterType,
    onboardingUser,
    activeUser,
    selectEmpresa,
  ]);

  useEffect(() => {
    fetchEmpresa();
    fetchSpecial();
  }, []);

  if (error) {
    return (
      <div>
        <p style={{textAlign: 'center'}}>Ocorreu um erro ao obter os dados.</p>
        <p style={{textAlign: 'center'}}>
          <a href="javascript:void(0)" onClick={fetchUsers}>
            Tentar novamente
          </a>
        </p>
      </div>
    );
  }

  return (
    <Dashboard>
      {firstLoading ? (
        <div className="flex-col center-a center-b" style={{height: '50vh'}}>
          <span style={{paddingTop: '10em'}}>
            <CircularProgress size={100} />
            <p> Carregando... </p>
          </span>
        </div>
      ) : (
        <div style={{overflow: 'hidden'}}>
          <div className={styles['filter-bar']}>
            <TextField
              variant="outlined"
              label="Buscar usuário"
              value={filterValue}
              onChange={handleFilterChange}
            />
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Admin?
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={adminSelect}
                onChange={handleAdminSelectChange}>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Receber Newsletter?
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={newsletterType}
                onChange={handleNewsletterTypeChange}>
                <MenuItem value={undefined}>Todos</MenuItem>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Tipo de Plano
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={subscriptionType}
                onChange={handleSubscriptionTypeChange}>
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="N">New</MenuItem>
                <MenuItem value="F">Free</MenuItem>
                <MenuItem value="M">Mensal</MenuItem>
                <MenuItem value="Y">Anual</MenuItem>
                <MenuItem value="G">Gift</MenuItem>
                <MenuItem value="S">Parceiro especial</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Empresa
              </InputLabel>
              <Select
                className={styles.select}
                variant="outlined"
                value={selectEmpresa}
                label="Selecione a empresa"
                onChange={e => setSelectEmpresa(e.target.value)}>
                <MenuItem value="">Todos</MenuItem>
                {getEmpresas &&
                  getEmpresas.map(e => {
                    return <MenuItem value={e.id}>{e.nomeFantasia}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                B2B
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={b2bFilter}
                onChange={handleB2bTypeChange}>
                <MenuItem value="">Todos</MenuItem>
                {getSpecial &&
                  getSpecial.map(e => {
                    return <MenuItem value={e.id}>{e.nome}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Onboarding?
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={onboardingUser}
                onChange={handleOnboardingChange}>
                <MenuItem value={undefined}>Todos</MenuItem>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="subscription-type-label"
                style={{paddingLeft: '15px'}}>
                Usuários Ativos?
              </InputLabel>
              <Select
                labelId="subscription-type-label"
                className={styles.select}
                variant="outlined"
                placeholder=""
                value={activeUser}
                onChange={handleUsersActiveChange}>
                <MenuItem value={undefined}>Todos</MenuItem>
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={styles['filter-button']}>
            <AddNewUser />
            <ButtonExport
              subscriptionType={subscriptionType}
              filterValue={filterValue}
              adminSelect={adminSelect}
              newsletterType={newsletterType}
              onboardingUser={onboardingUser}
              activeUser={activeUser}
              b2bFilter={b2bFilter}
              selectEmpresa={selectEmpresa}
            />
          </div>
          <TablePagination
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {isLoading ? (
            <div
              className="flex-col center-a center-b"
              style={{height: '50vh'}}>
              <span style={{paddingTop: '10em'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </span>
            </div>
          ) : (
            <>
              <div style={{marginBottom: 20}} />
              <List>
                {users.map(client => (
                  <UserItem key={client.id} history={history} user={client} />
                ))}
              </List>
            </>
          )}
          <TablePagination
            style={{
              marginBottom: '20px',
            }}
            component="div"
            count={totalPages}
            page={currentPage - 1}
            labelRowsPerPage={'Itens por página:'}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default UsersPage;
