import api from '../core/api';

const authenticate = async credentials => {
  const {status, data} = await api.post('/auth/login/admin', credentials);
  const {token, user} = data;
  api.token = token;
  localStorage.setItem('token', token);
  localStorage.setItem('empresa_id', user.empresa_id || null);
  localStorage.setItem('user_id', user.id || null);
  localStorage.setItem('adminGestor', user.adminMaster);

  // if (credentials.email === 'teste@gmail.com') {
  //   //mock teste
  //   localStorage.setItem('idEmpresa', 7);
  //   localStorage.setItem('adminGestor', false);
  // }
};

export default {authenticate};
