import React, {useState, useEffect} from 'react';
import {
  Grid,
  Avatar,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import EmailIcon from '@material-ui/icons/Email';
import {Link} from 'react-router-dom';
import api from '../core/api';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar} from 'notistack';
import {useAppContext} from './singletons/AppContext';
import Confirm from './Confirm';
import Empresas from '../models/Empresas';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  secondSubItem: {
    marginLeft: 20,
  },
}));

const UserItem = ({user, admin}) => {
  const userSelected = {...user};
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [subscription, setSubs] = useState(null);
  const [userActive, setUserActive] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const {onReloadData} = useAppContext();
  const classes = useStyles();

  const getSubscrition = async () => {
    setLoading(true);
    try {
      await api.get(`/subscriptions/users/${user.id}`).then(async ({data}) => {
        setSubs(data);
      });

      await api
        .post(`/access_logs/users/${user.id}`, {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
          endDate: new Date(),
        })
        .then(async ({data}) => {
          setUserActive(data);
        });
    } catch (e) {
      // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscrition();
  }, []);

  useEffect(() => {
    let plat = null;
    if (subscription?.transaction) {
      plat =
        subscription?.transaction?.platform === 'site'
          ? 'Site Medita'
          : subscription?.transaction?.platform === 'android'
          ? 'Google Play'
          : subscription?.transaction?.platform === 'ios'
          ? 'Apple Store'
          : 'Sem Plataforma';
    } else {
      plat =
        user?.registration_platform === 'site'
          ? 'Site Medita'
          : user?.registration_platform === 'android'
          ? 'Android'
          : user?.registration_platform === 'ios'
          ? 'Iphone'
          : 'Sem Plataforma';
    }

    setPlatform(plat);
  }, [subscription]);

  const onDeleteUser = async () => {
    const res = await Confirm('Deseja realmente remover este usuário?');
    if (res) {
      // console.log(res);
      try {
        // console.log(userSelected.id);

        await api.delete(`/users/${user.id}`);
        onReloadData();
        enqueueSnackbar('Deletado com Sucesso!');
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onSendRecoverPassword = async () => {
    const res = await Confirm(
      `Deseja enviar um email de recuperação de senha ou uma nova senha para ${user.email}?`,
    );

    if (res.reset) {
      setSendingEmail(true);
      try {
        await api.get(`/users/reset-pass/admin/${user.id}`, {
          email: user.email,
        });
        enqueueSnackbar(`Nova senha enviada para: ${user.email}`, {
          variant: 'success',
        });
        setSendingEmail(false);
        return true;
      } catch (e) {
        enqueueSnackbar(
          `Não foi possivel enviar a nova senha para o usuário: ${user.email} `,
          {
            variant: 'error',
          },
        );
        setSendingEmail(false);
        return false;
      }
    }

    if (res) {
      setSendingEmail(true);
      try {
        await api.post(`/auth/forgot`, {email: user.email});
        enqueueSnackbar(
          `Email de recuperação de senha enviado para ${user.email}`,
          {
            variant: 'success',
          },
        );
        setSendingEmail(false);
      } catch (e) {
        enqueueSnackbar(
          `Não foi possível enviar o email de recuperação de senha, tente novamente`,
          {
            variant: 'error',
          },
        );
        setSendingEmail(false);
      }
    }
  };

  const subscriptionType = premium => {
    switch (premium) {
      case 'F':
        return 'Free';
      case 'M':
        return 'Mensal';
      case 'Y':
        return 'Anual';
      case 'N':
        return 'New';
      case 'G':
        return 'Gift';
      case 'S':
        return 'Parceiro Especial';
      default:
        return premium;
    }
  };

  const initialLetter = user.name || '';

  return (
    <React.Fragment>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar
            src={user.thumbnailURL}
            alt="Remy Sharp"
            style={{textTransform: 'uppercase'}}>
            {user && initialLetter[0]}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={user.name}
          secondary={
            <Grid container>
              <Grid item xs={2}>
                <ListItemIcon>
                  <EmailIcon />
                  <Typography
                    style={{marginLeft: 8, wordBreak: 'break-word'}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    {user.email}
                  </Typography>
                </ListItemIcon>
              </Grid>
              {admin ? (
                <Grid item xs={3}>
                  <ListItemIcon>
                    <Typography
                      style={{marginLeft: 8}}
                      variant="body2"
                      className="flex-row center-b"
                      color="textPrimary">
                      Empresa: {user?.empresa?.nomeFantasia || ''}
                    </Typography>
                  </ListItemIcon>
                </Grid>
              ) : (
                <>
                  <Grid item xs={1}>
                    <ListItemIcon>
                      <Typography
                        style={{marginLeft: 8}}
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Recebe Newsletter:{' '}
                        {user.receiveNewsletter ? 'Sim' : 'Não'}
                      </Typography>
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={1}>
                    <ListItemIcon>
                      <Typography
                        style={{marginLeft: 8}}
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Assinatura: {subscriptionType(user.premium)}
                      </Typography>
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={1}>
                    <ListItemIcon>
                      <Typography
                        style={{marginLeft: 8}}
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Local de contratação:{' '}
                        {user?.premium === 'G' ? 'Site' : platform}
                      </Typography>
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={1}>
                    <ListItemIcon>
                      <Typography
                        style={{marginLeft: 8}}
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Assinatura anterior:{' '}
                        {user.subscriptions.length > 2 &&
                          subscriptionType(
                            user.subscriptions[user.subscriptions.length - 2]
                              .typePlan,
                          )}
                      </Typography>
                    </ListItemIcon>
                  </Grid>
                </>
              )}

              <Grid item xs={1}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    Data: {moment.utc(user.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </ListItemIcon>
              </Grid>

              <Grid item xs={1}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    Onboarding: {!!user.onboarding_date ? 'Sim' : 'Não'}
                  </Typography>
                </ListItemIcon>
              </Grid>
              <Grid item xs={1}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    Usuário ativo nos últimos 30 dias:{' '}
                    {loading ? '-' : !!userActive ? 'Sim' : 'Não'}
                  </Typography>
                </ListItemIcon>
              </Grid>
              <Grid item xs={3}>
                <ListItemIcon>
                  <Typography
                    style={{marginLeft: 8}}
                    variant="body2"
                    className="flex-row center-b"
                    color="textPrimary">
                    Empresa: {user?.empresa?.nomeFantasia || ''}
                  </Typography>
                </ListItemIcon>
              </Grid>

              <Grid item xs={2}>
                <ListItemIcon>
                  {user.premium !== 'F' &&
                  user.premium !== 'S' &&
                  user.subscriptions?.length > 0 ? (
                    <div style={{marginLeft: 20}}>
                      <Typography
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Início:{' '}
                        {moment
                          .utc(
                            user.subscriptions[user.subscriptions.length - 1]
                              .startDate,
                          )
                          .format('DD-MM-YYYY')}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="flex-row center-b"
                        color="textPrimary">
                        Fim:{' '}
                        {moment
                          .utc(
                            user.subscriptions[user.subscriptions.length - 1]
                              .endDate,
                          )
                          .format('DD-MM-YYYY')}
                      </Typography>
                    </div>
                  ) : null}
                </ListItemIcon>
              </Grid>
            </Grid>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              if (!sendingEmail) onSendRecoverPassword();
            }}
            edge="end">
            {!sendingEmail ? <VpnKeyIcon /> : <CircularProgress size={20} />}
          </IconButton>
          <IconButton component={Link} to={`/users/edit/${user.id}`} edge="end">
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeleteUser} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};
export default UserItem;
