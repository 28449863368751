import React from 'react';
import Dashboard from '../../layout/Dashboard';
import NewsletterForm from './components/NewsletterForm';

const NewsletterAdd = ({history}) => {
  const news = {
    id: '',
    body: '',
    button: '',
    link: '',
    title: '',
    subject: '',
    fileUrl: '',
    usersType: '',
    numberOfShots: 0,
  };

  return (
    <Dashboard>
      <NewsletterForm
        history={history}
        news={news}
        namePage={'Cadastrar Nova Newsletter'}
      />
    </Dashboard>
  );
};

export default NewsletterAdd;
